// This file contains variables for different themes

export const light = {
    
    body:"#353535",
    white:"#fff",
    black:"#000",
    text:"#F45303", // black shade
    textBlack:"#353535", //
    sub: "#D6D4D4",
    bodyRgba : "153, 153, 153",
    textRgba:"32,32,32",
    purple: 'red',
    gray: '0, 0%, 100%',

    carouselColor: '#EEEDDE',


    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1.3rem', // 1em = 16px
    fontlg:'1.5rem',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',

    fontButton:'0.875em',


    navHeight: '6.5rem',
}


export const dark = {
    
    body:"#202020",
    text: "#fff", // black shade
    bodyRgba :"32,32,32" ,
    textRgba: "255, 255, 255",

    carouselColor: '#EEEDDE',

    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', // 1em = 16px
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',

    fontButton:'0.875em',


    navHeight: '5rem',
}